<template>
  <DashboardSingle layout-type="claim">
    <ToastController />
    <slot />
  </DashboardSingle>
</template>

<script setup lang="ts">
import DashboardSingle from './dashboard-single.vue';

useHead({
  titleTemplate: titleChunk => {
    return titleChunk ? `${titleChunk} - OrderProtection` : 'Welcome - OrderProtection';
  },
});
</script>
